<template>
  <div class="max-w-screen-xl max-h-screen-tablet-cross-axis mx-auto py-2 h-screen overflow-hidden">
    <Header :back="false" :language="lang" 
                @langchanged="langchanged_"
                @modeChanged="modeChanged">
    </Header>
    <div v-if="btnDisabel">
      <Loader />
    </div>
    <div class="h-screen flex flex-col text-center w-6/12 mx-auto tablet:mt-0 mt-40" id="tablet-register-form">
      <img :src="logo" alt="Logo" class="w-44 mx-auto">
      <h2 class="font-heading text-h2 text-redDamask dark:text-redDamask">{{ $t('login.register') }}</h2>
      <p class="font-body text-bodyL text-sepiaBlack dark:text-halfColonialWhite">{{ $t('login.registerText') }}</p>
      <div class="hidden mx-auto" id="errorM">
        <SystemMessage :text="errorMsg" type='error' />
      </div>
      <Input :label="$t('login.email')" :placeholder="$t('login.email')" type="email" name="email" id="email" required />
      <Input :label="$t('login.name')" :placeholder="$t('login.name')" name="name" id="name" required />
      <Button :disabledBtn="btnDisabel" href="#" :text="$t('login.register')" v-on:click="quickLoginTablet()" fullWidth buttonType="primary" class="tablet:pt-36 pt-60"/>
    </div>
  </div>
</template>

<script>

import Header from "@/components/Header.vue";
import Button from '@/components/Button.vue';
import Input from '@/components/Input.vue';
import SystemMessage from '@/components/SystemMessage.vue';
import Loader from '@/components/Loader.vue';

export default {
  name: 'TabletRegister',
  components: {
    Header,
    Button,
    Input,
    SystemMessage,
    Loader
  },
  mounted() {
    this.$i18n.locale = this.lang;
    this.getLogo();
  },
  data() {
    return {
      baseURL: process.env.VUE_APP_AXIOS_URL,
      lang: localStorage.getItem('language') ? localStorage.getItem('language') : 'ro',
      btnDisabel: false,
      errorMsg: this.$t('login.bademail'),
      logo: '',
    }
  },
  methods: {
    langchanged(value) {
        this.lang = value;
    },
    getLogo: function() {
      const element = document.getElementById("app");
      if (element.classList.contains('darkMode')) {
        this.logo = require("../assets/logo_dark.png");
      } else {
        this.logo = require("../assets/logo_light.png");
      }
    },
    modeChanged(value) {
      this.getLogoAfterChange(value);
    },

    getLogoAfterChange(value) {
      if (value == 'dark') {
        this.logo = require("../assets/logo_dark.png");
      } else {
        this.logo = require("../assets/logo_light.png");
      }
    },
    quickLoginTablet() {
            this.btnDisabel = true;
            document.querySelector("#systemM").classList.remove("hidden");
            this.email = document.querySelector('#email').value;
            this.name = document.querySelector('#name').value;
            const variabels = {language: this.lang, email: this.email, name: this.name};
            this.axios.post(this.baseURL + `/wp-json/wp/v2/quick-login`, variabels, {})
            .then(response => {
                const response_data = response.data;
                if (response_data.error) {
                    document.querySelector("#errorM").classList.remove("hidden");
                    this.btnDisabel = false;
                } else {
                    // const login_user = {username: response_data.email, password: response_data.pin, name: response_data.name}
                    this.$router.push('/tablet-thank-you?pin=' + response.data.pin);
                    this.btnDisabel = false;
                    
                }
                    
            }).catch( (error) => {
                // console.log(error);
                this.btnDisabel = false;
            });
        },



  }
}
</script>

<style>
#tablet-register-form #email,
#tablet-register-form #name {
  height: 3rem;
  line-height: 1.5;
}

#tablet-register-form #name::placeholder,
#tablet-register-form #email::placeholder {
  overflow: visible;
}

</style>